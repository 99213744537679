// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/homepage-dots-p-1600.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.ornament_digital-atom {
  position: sticky;
}

.ornament_digital-atom.pos-left {
  top: 69%;
  left: 23%;
  scale: 0.69;
}
.ornament_digital-atom.pos-right {
  bottom: 69%;
  left: 69%
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--auth-linear-bg);
}

.login-text {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
}

.note {
  margin-top: 20px;
  padding: 0 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.oauth-form {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  color: #ffffff;
  background-color: #242424;
  gap: 5rem;
  border-radius: 8px;
  box-shadow: 0px 1px 15px rgba(199, 199, 199, 0.5);
}
.oauth-logo {
  width: 100px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/login/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,yDAAqE;AACvE;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,QAAQ;EACR,SAAS;EACT,WAAW;AACb;AACA;EACE,WAAW;EACX;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,SAAS;EACT,kBAAkB;EAClB,iDAAiD;AACnD;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".auth-container:before {\n  content: '';\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-image: url(\"../../../assets/img/homepage-dots-p-1600.png\");\n}\n\n.ornament_digital-atom {\n  position: sticky;\n}\n\n.ornament_digital-atom.pos-left {\n  top: 69%;\n  left: 23%;\n  scale: 0.69;\n}\n.ornament_digital-atom.pos-right {\n  bottom: 69%;\n  left: 69%\n}\n\n.login {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background: var(--auth-linear-bg);\n}\n\n.login-text {\n  font-size: 18px;\n  font-weight: 300;\n  text-align: center;\n  user-select: none;\n}\n\n.note {\n  margin-top: 20px;\n  padding: 0 40px;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n.oauth-form {\n  width: 300px;\n  height: 300px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  flex-wrap: wrap;\n  color: #ffffff;\n  background-color: #242424;\n  gap: 5rem;\n  border-radius: 8px;\n  box-shadow: 0px 1px 15px rgba(199, 199, 199, 0.5);\n}\n.oauth-logo {\n  width: 100px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
