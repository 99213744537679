import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

type Props = {};

export const LoadingCourseCard = (props: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={3.8}
      gap={2}
      sx={{
        backgroundColor: "var(--landing-course-card-bg)",
        borderRadius: "15px",
        boxShadow: 3,
        minHeight: "200px",
        padding: "15px",
        position: "relative",
        cursor: "pointer",
        ":hover": {
          border: "2px solid var(--landing-course-card-hover-border)",
        },
      }}
    >
      <Skeleton variant="text" width="70%" height={20} />
      <Skeleton variant="text" width="100%" height={15} />
      <Skeleton variant="text" width="100%" height={15} />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Skeleton variant="text" width="20%" height={15} />
        <Skeleton variant="text" width="20%" height={15} />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Skeleton variant="text" width="20%" height={15} />
        {/* <Skeleton
          variant="circular"
          width="100px"
          height={20}
          sx={{ borderRadius: "10px" }}
        /> */}
      </Box>
    </Grid>
  );
};

export const LoadingProfileCard = (props: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={5.5}
      lg={3.5}
      gap={2}
      sx={{
        backgroundColor: "var(--landing-course-card-bg)",
        borderRadius: "15px",
        boxShadow: 3,
        minHeight: "200px",
        padding: "15px",
        position: "relative",
        cursor: "pointer",
        ":hover": {
          border: "2px solid var(--landing-course-card-hover-border)",
        },
      }}
    >
      <Box width="20%">
        <Skeleton variant="circular" width={80} height={80} />
      </Box>
      <Box
        width="70%"
        height="100%"
        display="flex"
        flexDirection="column"
        gap="1.5em"
      >
        <Skeleton variant="text" width="90%" height={60} />
        <Skeleton variant="text" width="100%" height={20} />
        <Box sx={{ display: "flex", gap: "1em", width: "100%" }}>
          <Skeleton variant="rounded" width="20%" height={20} />
          <Skeleton variant="rounded" width="20%" height={20} />
        </Box>
        <Box sx={{ display: "flex", gap: "1em", width: "100%" }}>
          <Skeleton variant="rounded" width="20%" height={20} />
          <Skeleton variant="rounded" width="20%" height={20} />
        </Box>
      </Box>
    </Grid>
  );
};

export const LoadingBlogCard = (props: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={5.8}
      gap={1}
      sx={{
        backgroundColor: "var(--landing-course-card-bg)",
        borderRadius: "15px",
        boxShadow: 3,
        minHeight: "200px",
        padding: "15px",
        position: "relative",
        cursor: "pointer",
        ":hover": {
          border: "2px solid var(--landing-course-card-hover-border)",
        },
      }}
    >
      <Box width="70%" display="flex" flexDirection="column" gap={1}>
        <Skeleton variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={20} />
      </Box>
      <Box
        width="20%"
        height="100%"
        display="flex"
        flexDirection="column"
        gap="1.5em"
      >
        <Skeleton variant="text" width={100} height={100} />
      </Box>
    </Grid>
  );
};
