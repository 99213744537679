// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-parent-container{
    display: flex !important;
    flex-direction: column;
    background-color: var(--bg-primary-clr1);
    color: var(--text-primary-clr1);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/layout/index.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,wCAAwC;IACxC,+BAA+B;AACnC","sourcesContent":[".layout-parent-container{\n    display: flex !important;\n    flex-direction: column;\n    background-color: var(--bg-primary-clr1);\n    color: var(--text-primary-clr1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
