// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthorized-ui-img {
  min-width: 500px;
  max-width: 700px;
  height: auto;
}
@media only screen and (max-width: 1000px) {
    .unauthorized-ui-img {
        min-width: 100%;
        max-width: 100%;
        height: auto;
      }
  }
`, "",{"version":3,"sources":["webpack://./src/app/pages/Admin/components/unauthorizedUi/UnauthorizedUI.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;AACA;IACI;QACI,eAAe;QACf,eAAe;QACf,YAAY;MACd;EACJ","sourcesContent":[".unauthorized-ui-img {\n  min-width: 500px;\n  max-width: 700px;\n  height: auto;\n}\n@media only screen and (max-width: 1000px) {\n    .unauthorized-ui-img {\n        min-width: 100%;\n        max-width: 100%;\n        height: auto;\n      }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
