// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-editor-navigation {
    background: var(--bg-clr1);
    height: 84px;
    border-top: solid 1px #2b2a30;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }
  .image-editor-navigation__button {
    margin-left: 8px;
    margin-right: 8px;
  }
  .image-editor-navigation__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-editor-navigation__upload-input {
    display: none;
  }
  @media (max-width: 540px) {
    .image-editor-navigation {
      padding-left: 8px;
      padding-right: 8px;
    }
    .image-editor-navigation__button {
      margin-left: 4px;
      margin-right: 4px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/pages/editProfile/components/profile-details/components/imageEditor/components/navigation/index.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,YAAY;IACZ,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,aAAa;EACf;EACA;IACE;MACE,iBAAiB;MACjB,kBAAkB;IACpB;IACA;MACE,gBAAgB;MAChB,iBAAiB;IACnB;EACF","sourcesContent":[".image-editor-navigation {\n    background: var(--bg-clr1);\n    height: 84px;\n    border-top: solid 1px #2b2a30;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n  .image-editor-navigation__button {\n    margin-left: 8px;\n    margin-right: 8px;\n  }\n  .image-editor-navigation__buttons {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .image-editor-navigation__upload-input {\n    display: none;\n  }\n  @media (max-width: 540px) {\n    .image-editor-navigation {\n      padding-left: 8px;\n      padding-right: 8px;\n    }\n    .image-editor-navigation__button {\n      margin-left: 4px;\n      margin-right: 4px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
