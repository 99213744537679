/**
 * @fileOverview This file contains the axiosClient utility function and related constants for making HTTP requests.
 * @module axiosClient
 */
import axios from "axios";
import Cookies from "universal-cookie";
import { DomainObjectType } from "../types/domainType";
import qBraidConstants from "./domainObjects/qbraid/qbraidConstants";
import queraConstants from "./domainObjects/quera/queraConstants";
import qusteamConstants from "./domainObjects/qusteam/qusteamConstants";

axios.defaults.withCredentials = false;
let apiURL = "";
let labURL = "";
let qbookURL = "";
let accountURL = "";
let enterpriseGatewayUrl = "";
let defaultKernelName = "";
let domainObject: DomainObjectType = qBraidConstants;

/**
 * The domain of the application. If you're running the application locally, make sure to add the domain to the .env file.
 * @type {string}
 */
export const domain = process.env.REACT_APP_DOMAIN;
/**
 * Configuration options for the qbraid environment.
 * @type {Object}
 */
const qbraid = {
  name: "qbraid",
  live: /qbook.qbraid/,
  staging: /qbook-staging.qbraid/,
  account: "https://account.qbraid.com",
  accountStaging: "https://account-staging.qbraid.com",
  api: "https://api.qbraid.com/api",
  apiStaging: "https://api-staging-1.qbraid.com/api",
  lab: "https://lab.qbraid.com",
  labStaging: "https://lab-staging.qbraid.com",
  qbook: "https://qbook.qbraid.com",
  qbookStaging: "https://qbook-staging.qbraid.com",
  enterpriseGatewayUrl: "qbook-staging.k8s.qbraid.com", // No https since wss and https will be used
  defaultKernelName: "qbraid_python",
};

/**
 * Configuration options for the qusteam environment.
 * @type {Object}
 */
const qusteam = {
  name: "qusteam",
  live: /qbook.qusteam/,
  staging: /qbook.qusteam/,
  account: "https://account.qusteam.org",
  accountStaging: "https://account.qusteam.org",
  api: "https://api.qbraid.com/api",
  apiStaging: "https://api-staging-1.qbraid.com/api",
  lab: "https://lab.qusteam.org",
  labStaging: "https://lab.qusteam.org",
  qbook: "https://qbook.qusteam.org",
  qbookStaging: "https://qbook.qusteam.org",
  enterpriseGatewayUrl: "qbook-staging.k8s.qbraid.com", // No https since wss and https will be used
  defaultKernelName: "qbraid_python",
};

/**
 * Configuration options for the qusteam environment.
 * @type {Object}
 */
const quera = {
  name: "quera",
  live: /qbook.quera/,
  staging: /qbook-stg.quera/,
  account: "https://quera.com/",
  accountStaging: "https://quera.com",
  api: "https://api.qbraid.com/api",
  apiStaging: "https://api-staging-1.qbraid.com/api",
  lab: "https://lab.qbraid.com",
  labStaging: "https://lab.qbraid.com",
  qbook: "https://qbook.quera.com",
  qbookStaging: "https://qbook-stg.quera.com",
  enterpriseGatewayUrl: "qbook-staging.k8s.qbraid.com",
  defaultKernelName: "quera_python",
};

/**
 * Configuration options for the localhost environment.
 * @type {Object}
 */

const localhost = {
  account: "http://localhost:3000",
  // api: "http://localhost:3001/api",
  api: "https://api-staging-1.qbraid.com/api",
  qbook: "http://localhost:3000",
  lab: "http://localhost:8080",
};

let current: any = {};

if (domain?.toLowerCase() === qbraid.name.toLowerCase()) {
  // use qbraid option
  current = qbraid;
  domainObject = qBraidConstants;
} else if (domain?.toLowerCase() === qusteam.name.toLowerCase()) {
  // use qusteam option
  current = qusteam;
  domainObject = qusteamConstants;
} else if (domain?.toLowerCase() === quera.name.toLowerCase()) {
  // use quera option
  current = quera;
  domainObject = queraConstants;
}

const path = window.location.href;
const live = !!path.match(current.live);
const staging = !!path.match(current.staging);

if (live) {
  accountURL = current.account;
  apiURL = current.api;
  labURL = current.lab;
  qbookURL = current.qbook;
  enterpriseGatewayUrl = current.enterpriseGatewayUrl;
  defaultKernelName = current.defaultKernelName;
} else if (staging) {
  accountURL = current.accountStaging;
  apiURL = current.apiStaging;
  labURL = current.labStaging;
  qbookURL = current.qbookStaging;
  enterpriseGatewayUrl = current.enterpriseGatewayUrl;
  defaultKernelName = current.defaultKernelName;
} else {
  accountURL = localhost.account;
  apiURL = localhost.api;
  labURL = qbraid.labStaging;
  qbookURL = localhost.qbook;
  enterpriseGatewayUrl = current.enterpriseGatewayUrl;
  defaultKernelName = current.defaultKernelName;
}
// append qbook url to cognito logout url
domainObject.awsCognito.cognitoLogoutUrl += qbookURL;

const siteUrl = `${path.split("/")[0]}//${window.location.href.split("/")[2]}`;
export const LAB_URL = labURL;
export const API_URL = apiURL;
export const QBOOK_URL = qbookURL;
export const ACCOUNT_URL = accountURL;
export const DOMAIN_CONFIG_OBJ = domainObject;
export const SITE_URL = siteUrl;
export const ENTER_PRISE_GATE_WAY = enterpriseGatewayUrl;
export const DEFAULT_KERNEL_NAME = defaultKernelName;

// let email = 'markovshama@gmail.com'
// let refreshToken = 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.SjmfZWkV_Wwzj6bvgH8bCUgsbCsKtUnFOK2l709VySyyV8RTrC09cXV1L6meIJjEcqELqO-1BygSBQUXDsiJ26pLUbPTb5Mm6JV1k4_Q-1tQkJtO2nEyknyCOGmhetg83zkWO69Jf_lLD01fAFNYh4x_Kjd4ozRuYbP1bOoMs9gJirsFXksJlDBEjAjJHnYk8Qi4eOZHsMrUT0y1yeoYkw-NnAai83FpyDGVRh_FE1I8d_cuY2n5iscBfKNTEfPK4s_tzjeDcgwob4GhPs1qR4Fq19K9RuKBQNsxneVKbH7wsBdCqzfM3A3vSEaXKPxPLQuIezOFIcaZ5bfc6Egxrw.Va0ygzGoHUkFdrYV.WMfIMR_L7XsBOR5f_uVvrjy4a0m4nAihkIA1b4oPEgOraj9ZVyJybMIDgzd4KaglEmer4GgRMOMAXYxQx_Fm8hwYLB_dIEn5vFxE2bLn_1cNWf6TwQb6us2HHmgJ16wUGPl9hzHv9WuREUfVaY56vAKYifi-3wgohvdAM_XdFy03JjgralaqRnB1cO2lU7JzBWKS9hzzsXIPFd0Ct6Jo1OKL062w8j1tT3vDiE0UznkFVy_PAFTE64X_TiRjbG3PrbMzVza6auLiOH_LnR_5gFTOPK8Pq8spqqMH7vsAQeUYpAvUDk-LIalYnfq2hOE6bloe55QR-JmpF9i-NLNwBQinpjM_YW2qNx11-XJ6qh8YJ--ESH2zPNbv2YfliRKNOnNqLtcufb9727bD39ElAdIsi_8sJvde3KRFJtGX1MHPyhIZdN28flXkslqKB8calr1wrXV_KP7Y-JhjpYkiXD4kMo6LnnkDH4T7sdM931fEnpJqzYAc_2aFDJXhE13ZjLGfQwPcBcSF7hKpG8MudssAlNIrfOv3gBMhYQVwtjZHdSrktP7taJ7Zv6d3w4tqElktWO4dvGy44O5xbhWJCbX-Fu-ysNnqywgf2NlOdII7m6tM2etDiERFEHKjRUtd_ly4YIO2QwV3JqQCixO_Ia9Yu7acJ9lIQxORRGz_WkaqCDbkRvEIU9ZjKdt_guhBb9lT0bFidAmkhMn14VDeW5uztvI5VoXKUR6iTX7GhX7Pl6WPTwEr-PLa3IbRffNsiU30y4kc8fJ8YXIK4doQpPh-anLy_CO_5_-A7-TcrOsa3gxJq58HixIebbA8OXLK1nHlXEWU1hWAxpBGm5o5UmEqP_2xOIvSHlDKQyqe1_fmjyzMSn2dC64SwKO6UnNPRFWzXjAary7pWSp3CuUnPXeT2XZedHiMQuGvvhVpxYphVGB8CZ6zw5oZZ0wqSJGm6fndrBbzlweb7gQUgO7BFpX4X8IWGLdXppe1HKlPtaatW7QsUUJaKPDTAaw9ZsBZVwvFcSPHN6CmRVtDXl7LOjZBg7UtD82wGzdnUN3UMjZZH28W6FRsRxMY1snuP7w9tVI2yUbjvL_TQ7nV3CA_To3uFTMNoqpJYnsv4SefDLlkHFuWogW7-J3r4cpz_YbO4ZZkPURn5BKNlHFfw1gd6yNPDLV4Suvfpn-ldS0dXRsDTCsA4NuICoiYIUukesrTBE3WPbKXvfqzW2yCEiS8Mvb3btzoL89cUdyVVjp7YYxe5xNGi_VxdOU0pou0grOVQqpsIj-2H9Udl5icHvNYlBSJ9Yz56e7M4ZuUufF86CyaDXChiklgnGo-8Q.7pqO4zPTcrXC1bOtQXbfTA'

const cookies = new Cookies();
export const email = cookies.get("EMAIL");
export const refreshToken = cookies.get("REFRESH");
export const accessToken = cookies.get("ACCESS");

/**
 * The axios client function for making HTTP requests to api.
 * @function axiosClient
 */
export function axiosClient() {
  // gets the email and refresh cookies

  const defaultOptions = {
    baseURL: apiURL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",

      // "Access-Control-Allow-Credentials": true,
      accept: "application/json",
      "refresh-token": refreshToken,
      // eslint-disable-next-line
      email: email,
      domain: domain,
    },
  };

  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    return config;
  });

  // instance.defaults.headers.common[
  //   "Authorization"
  // ] = store.getState().mainStore.accessToken;

  return instance;
}

export default axiosClient;
