import React, { useEffect, useState } from "react";
import NavigationManager from "./components/navigationManager";
import KernelInfo from "./components/kernalInfo";
import { useAppDispatch, useAppSelector } from "../../../reduxStore/hooks";
import { handleUpdateKernelState } from "../../../reduxStore/features/kernalSlice";
import {
  killWebsocket,
  runStartupSequenceAction,
} from "../../../reduxStore/middleware/kernelMiddleware";
import {
  DEFAULT_KERNEL_NAME,
  DOMAIN_CONFIG_OBJ,
} from "../../../utils/axiosInstance";
import { QBOOK_DOMAIN_VALUES } from "../../../utils/constants/domainNameValues";
import "./index.css";

type Props = {
  kernel?: any;
  reloadIfLoggedOut?: boolean;
};

const QbookHeader = (props: Props) => {
  const { email } = useAppSelector((state) => state.auth);
  const [kernelDisplayName, setKernelDisplayName] = useState("qBraid");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [kernelManagerOpen, setKernelManagerOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const runKernalStartupSequence = () => {
    let kernelName = DEFAULT_KERNEL_NAME;
    // depending on the default kernel name set the kernel name to be displayed
    // kernel spec can be retrieved from => GET : https://qbook-staging.k8s.qbraid.com/api/kernelspecs
    switch (true) {
      case kernelName === "quera_python":
        setKernelDisplayName("QuEra");
        break;

      default:
        setKernelDisplayName("qBraid");
        break;
    }
    if (props.kernel && props.kernel.name !== "python3") {
      kernelName = props.kernel.name;
      setKernelDisplayName(props.kernel.name);
    }
    dispatch(handleUpdateKernelState("Starting..."));
    dispatch(runStartupSequenceAction({ kernel_type: kernelName }));
  };
  const runKernalDisconectSequence = () => {
    dispatch(killWebsocket());
  };
  // get user
  useEffect(() => {
    email && runKernalStartupSequence();
    return () => {
      email && runKernalDisconectSequence();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <div
      id="qbook-header-container-kernel-bar"
      className={`kernel-bar-container ${
        DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
          ? "quera-kernel-bar-container"
          : ""
      }`}
      style={{ zIndex: 5000 }}
      cy-data="qbook-header-container"
    >
      <NavigationManager />
      <KernelInfo
        kernelDisplayName={kernelDisplayName}
        kernelManagerOpen={kernelManagerOpen}
        reloadIfLoggedOut={props.reloadIfLoggedOut}
      />
    </div>
  );
};

export default QbookHeader;
