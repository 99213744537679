// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-editor {
	color: var(--brand-color-3);
	max-height: 100%;
}
.image-editor__preview {
	height: 45px;
	width: 45px;
	background-color: black;
	outline: solid 2px var(--border-clr1);
	position: absolute;
	left: 20px;
	top: 20px;
	border-radius: 50%;
}
.image-editor__cropper {
	background: #0f0e13;
	height: 400px;
	max-height: 100%;
	position: relative;
}
.image-editor__slider {
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	position: absolute;
}
.image-editor__cropper-overlay {
	transition: 0.5s;
}
.image-editor__cropper-overlay--faded {
	color: rgba(0, 0, 0, 0.9);
}
.image-editor__service {
	pointer-events: none;
	left: -100%;
	position: absolute;
}
.image-editor__reset-button {
	/* position: absolute;
	right: 20px;
	top: 20px; */
	background: rgba(255, 255, 255, 0.398);
	opacity: 1 !important;
}
.image-editor__reset-button:hover {
	fill: #61dafb;
	background: rgba(255, 255, 255, 0.2);
}
.image-editor__reset-button--hidden {
	opacity: 0;
	visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/editProfile/components/profile-details/components/imageEditor/index.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,gBAAgB;AACjB;AACA;CACC,YAAY;CACZ,WAAW;CACX,uBAAuB;CACvB,qCAAqC;CACrC,kBAAkB;CAClB,UAAU;CACV,SAAS;CACT,kBAAkB;AACnB;AACA;CACC,mBAAmB;CACnB,aAAa;CACb,gBAAgB;CAChB,kBAAkB;AACnB;AACA;CACC,WAAW;CACX,SAAS;CACT,2BAA2B;CAC3B,YAAY;CACZ,kBAAkB;AACnB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,yBAAyB;AAC1B;AACA;CACC,oBAAoB;CACpB,WAAW;CACX,kBAAkB;AACnB;AACA;CACC;;aAEY;CACZ,sCAAsC;CACtC,qBAAqB;AACtB;AACA;CACC,aAAa;CACb,oCAAoC;AACrC;AACA;CACC,UAAU;CACV,kBAAkB;AACnB","sourcesContent":[".image-editor {\n\tcolor: var(--brand-color-3);\n\tmax-height: 100%;\n}\n.image-editor__preview {\n\theight: 45px;\n\twidth: 45px;\n\tbackground-color: black;\n\toutline: solid 2px var(--border-clr1);\n\tposition: absolute;\n\tleft: 20px;\n\ttop: 20px;\n\tborder-radius: 50%;\n}\n.image-editor__cropper {\n\tbackground: #0f0e13;\n\theight: 400px;\n\tmax-height: 100%;\n\tposition: relative;\n}\n.image-editor__slider {\n\twidth: 100%;\n\tleft: 50%;\n\ttransform: translateX(-50%);\n\tbottom: 20px;\n\tposition: absolute;\n}\n.image-editor__cropper-overlay {\n\ttransition: 0.5s;\n}\n.image-editor__cropper-overlay--faded {\n\tcolor: rgba(0, 0, 0, 0.9);\n}\n.image-editor__service {\n\tpointer-events: none;\n\tleft: -100%;\n\tposition: absolute;\n}\n.image-editor__reset-button {\n\t/* position: absolute;\n\tright: 20px;\n\ttop: 20px; */\n\tbackground: rgba(255, 255, 255, 0.398);\n\topacity: 1 !important;\n}\n.image-editor__reset-button:hover {\n\tfill: #61dafb;\n\tbackground: rgba(255, 255, 255, 0.2);\n}\n.image-editor__reset-button--hidden {\n\topacity: 0;\n\tvisibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
