import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import {
  QUERY_COURSE,
  QUERY_FILE
} from "../../../../utils/constants/urlPramsConstants";
import { LearnInfoType } from "../../../../reduxStore/types/LearnTypes";
import { useAppDispatch, useAppSelector } from "../../../../reduxStore/hooks";
import { registerInToCourseAction } from "../../../../reduxStore/middleware/learnMiddleware";
import React,{ useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

type Props = {
  courseName: string;
  courseDescription: string;
  totalStudents: string | number;
  content: LearnInfoType[];
  courseId: string;
  courseAuthor: string;
  visibility?: string;
  courseOwnerId: string;
};

const CourseCard = ({
  courseName,
  courseDescription,
  content,
  totalStudents,
  courseId,
  courseAuthor,
  courseOwnerId
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.auth);
  const [register, setRegister] = useState(false);
  const handleViewCourseClick = () => {
    // if course owner id and user id is same, redirect user to his own course
    if (userData) {
      if (courseOwnerId === userData?._id) {
        console.log("Course Owner");
        return navigate(
          `/learn/?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${content[0]._id}`
        );
      }
      setRegister(true);
      dispatch(registerInToCourseAction({ courseId: courseId })).then(
        (data: any) => {
          if (data?.payload?.status === 200 || data?.payload?.status === 409) {
            // 200 : registration success, 409 : already registered
            // store the courseId in local storage, to prevent subsequent registration request for this course from /learn/ route
            // localStorage.setItem('qbookCurrentCourse',courseId)
            return navigate(
              `/learn/?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${content[0]._id}`
            );
          }
          Swal.fire({
            icon: "error",
            title: "Errored",
            text:
              data?.payload?.data?.message ||
              "Something went wrong, Please try again."
          });
          setRegister(false);
          // if registration errored due to some other reasons don't forward to course page
          return;
        }
      );
    }
    // if user is not present only public courses will be loaded, so redirect to /learn/
    return navigate(
      `/learn/?${QUERY_COURSE}=${courseId}&${QUERY_FILE}=${content[0]._id}`
    );
  };
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={3.8}
      sx={{
        backgroundColor: "var(--landing-course-card-bg)",
        borderRadius: "15px",
        boxShadow: 3,
        minHeight: "200px",
        padding: "15px",
        cursor: "pointer",
        position: "relative",
        ":hover": {
          border: "2px solid var(--landing-course-card-hover-border)"
        },
        overflow: "hidden"
      }}
      onClick={handleViewCourseClick}
      cy-data="qbook-landing-course-card"
    >
      <Typography
        sx={{
          fontSize: "25px",
          fontWeight: 700,
          fontFamily: "var(--global-font-family-secondary)",
          color: "text.primary",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
        cy-data="qbook-landing-course-card-name"
      >
        {courseName}
      </Typography>
      <Box
        sx={{
          width: "100%"
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            color: "var(--landing-course-card-text2)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical"
          }}
          cy-data="qbook-landing-course-card-description"
        >
          {courseDescription}
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        marginTop={2}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1em",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: "var(--landing-course-card-text3)"
            }}
          >
            Author :
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: "var(--landing-course-card-text3)"
            }}
          >
            {courseAuthor || "N/A"}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            color: "var(--landing-course-card-text2)"
          }}
          cy-data="landing-course-card-content-length"
        >{`Chapters : ${content?.length || 0}`}</Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 600,
          color: "var(--landing-course-card-text3)",
          position: "absolute",
          bottom: 20
        }}
        cy-data="landing-course-card-students-count"
      >
        {`Enrolled Students ${totalStudents ? totalStudents : "N/A"}`}
      </Typography>
      {/* <Button
        variant={
          DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
            ? "qbookAuth"
            : "qbookStyledBtn"
        }
        color={
          DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
            ? "authSubmit"
            : "primary"
        }
        sx={{
          textTransform: "none",
          position: "absolute",
          bottom: "20px",
          right: "20px",
          padding:
            DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
              ? "5px"
              : "5px 2px",
        }}
        onClick={() => handleViewCourseClick()}
      >
        View
      </Button> */}
      <Dialog
        open={register}
        disablePortal
        sx={{
          position: "absolute",
          ":root": { position: "absolute" },
          "& .MuiBackdrop-root": {
            position: "absolute"
          },
          "& .MuiDialog-root": {
            position: "absolute"
          }
        }}
      >
        <DialogContent>
          <Stack
            flexDirection="row"
            gap={1}
            cy-data="qbook-landing-course-card-loader"
          >
            <ThreeCircles
              height="30"
              width="30"
              color={"var(--accent-clr2)"}
              ariaLabel="grid-loading"
              wrapperClass=""
              visible
            />
            <Typography fontSize={16} color="text.primary">
              {"Processing."}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default React.memo(CourseCard);
