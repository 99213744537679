// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-in-top {
	animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-out-top {
	animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
  @keyframes slide-in-top {
    0% {
      transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-out-top {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-1000px);
      opacity: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/pages/landingPage/components/CommonSearch.css"],"names":[],"mappings":"AAAA;CAES,0EAA0E;AACnF;AACA;CAES,2EAA2E;AACpF;EAcE;IACE;MAEU,8BAA8B;MACtC,UAAU;IACZ;IACA;MAEU,wBAAwB;MAChC,UAAU;IACZ;EACF;EAcA;IACE;MAEU,wBAAwB;MAChC,UAAU;IACZ;IACA;MAEU,8BAA8B;MACtC,UAAU;IACZ;EACF","sourcesContent":[".slide-in-top {\n\t-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;\n\t        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;\n}\n.slide-out-top {\n\t-webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;\n\t        animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;\n}\n\n@-webkit-keyframes slide-in-top {\n    0% {\n      -webkit-transform: translateY(-1000px);\n              transform: translateY(-1000px);\n      opacity: 0;\n    }\n    100% {\n      -webkit-transform: translateY(0);\n              transform: translateY(0);\n      opacity: 1;\n    }\n  }\n  @keyframes slide-in-top {\n    0% {\n      -webkit-transform: translateY(-1000px);\n              transform: translateY(-1000px);\n      opacity: 0;\n    }\n    100% {\n      -webkit-transform: translateY(0);\n              transform: translateY(0);\n      opacity: 1;\n    }\n  }\n  \n  @-webkit-keyframes slide-out-top {\n    0% {\n      -webkit-transform: translateY(0);\n              transform: translateY(0);\n      opacity: 1;\n    }\n    100% {\n      -webkit-transform: translateY(-1000px);\n              transform: translateY(-1000px);\n      opacity: 0;\n    }\n  }\n  @keyframes slide-out-top {\n    0% {\n      -webkit-transform: translateY(0);\n              transform: translateY(0);\n      opacity: 1;\n    }\n    100% {\n      -webkit-transform: translateY(-1000px);\n              transform: translateY(-1000px);\n      opacity: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
