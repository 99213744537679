import { Box, Button, Divider, Grid, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomSearchField from "./components/SearchField";
import WhyChooseCard, { WhyChooseData } from "./components/WhyChooseCard";
import CourseCard from "./components/CourseCard";
import { useAppDispatch, useAppSelector } from "../../../reduxStore/hooks";
import { LoadingBlogCard, LoadingCourseCard } from "./components/LoadingScreen";
import { loadPublicCoursesAction } from "../../../reduxStore/middleware/learnMiddleware";
import { PublicCourseType } from "../../../reduxStore/types/LearnTypes";
import {
  bookmarkBlogAction,
  getMoreBlogsAction,
} from "../../../reduxStore/middleware/blogsMiddleware";
import { handleSnackbarOpen } from "../../../reduxStore/features/snackbarSlice";
import BlogCard from "../../components/BlogCard";
import { DOMAIN_CONFIG_OBJ } from "../../../utils/axiosInstance";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { QBOOK_DOMAIN_VALUES } from "../../../utils/constants/domainNameValues";
import atomImage from "../../../assets/img/digital-atom.png";
import CommonSearch from "./components/CommonSearch";
type Props = {};
type LandingLabelType = {
  title: string;
  text: string;
};

const LandingLabel = ({ title, text }: LandingLabelType) => {
  return (
    <>
      <Typography
        variant="body1"
        sx={{
          alignSelf: "center",
          backgroundColor: "var(--landing-label-bg1)",
          color: "var(--landing-label-text1)",
          padding: "0 21px",
          borderRadius: "var(--landing-label-border-radius)",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "var(--landing-text-clr1)",
          fontWeight: 600,
          maxWidth: "700px",
          alignSelf: "center",
          fontSize: "32px",
        }}
      >
        {text}
      </Typography>
    </>
  );
};

const Landing = (props: Props) => {
  useDocumentTitle(`Qbook | ${DOMAIN_CONFIG_OBJ.domainName}`);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email, userData } = useAppSelector((state) => state.auth);
  const { listedPublicCourses, remainingCourses } = useAppSelector(
    (state) => state.learn
  );
  const { blogArray } = useAppSelector((state) => state.blogs);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(true);
  const [loadingBlogs, setLoadingBlogs] = useState<boolean>(true);
  const [publicCourses, setPublicCourses] =
    useState<Array<PublicCourseType>>(listedPublicCourses);
  const [failedToLoadCourses, setFailedToLoadCourses] =
    useState<boolean>(false);
  const [coursePage, setCoursePage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

  const loadPublicCourses = async () => {
    setLoadingCourses(true);
    try {
      await dispatch(loadPublicCoursesAction({ page: coursePage }));
    } catch (error) {
      setFailedToLoadCourses(true);
    } finally {
      setLoadingCourses(false);
    }
  };

  // const loadTutorsProfiles = async () => {
  //   await dispatch(loadCourseTutorProfilesAction({ page: tutorPage }));
  // };

  function searchCourses(courseList: Array<PublicCourseType>, query: string) {
    if (query.length < 1) {
      return courseList;
    }
    const resultantCourses = courseList.filter((obj: PublicCourseType) => {
      return Object.values(obj).some((val: any) => {
        if (!Array.isArray(val) && typeof val === "string") {
          return val.toLowerCase().includes(query.toLowerCase());
        }
        return false;
      });
    });
    return resultantCourses;
  }

  const handleSearchCourseFocus = (value: string) => {
    // scrollCoursesRef.current?.scrollIntoView({ behavior: "smooth" });
    // setSearchQuery(value);
    const headerElement = document.getElementById(
      "qbook-header-container-kernel-bar"
    );
    if (headerElement) {
      headerElement.style.zIndex = "0";
    }
    setShowSearchModal(true);
  };

  const handleLoadMoreCourses = () => {
    if (remainingCourses <= 0) {
      return;
    }
    setCoursePage((pre) => pre + 1);
  };

  const handleViewMoreBlogs = () => {
    navigate("/blogs");
  };
  const handleAddBookmark = (id: string): void => {
    if (!email) {
      dispatch(
        handleSnackbarOpen({
          severity: "warning",
          message: "Please login to bookmark blogs!",
        })
      );
      return;
    }
    userData?._id &&
      dispatch(bookmarkBlogAction({ blogId: id, userId: userData?._id }));
  };

  const handleSearchModal = () => {
    setShowSearchModal((pre) => !pre);
    const headerElement = document.getElementById(
      "qbook-header-container-kernel-bar"
    );
    if (headerElement) {
      headerElement.style.zIndex = "5000";
    }
  };
  useEffect(() => {
    let filteredItems = listedPublicCourses || [];
    filteredItems = searchCourses(filteredItems, searchQuery);
    setPublicCourses(filteredItems);
  }, [searchQuery, listedPublicCourses]);

  useEffect(() => {
    loadPublicCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursePage]);
  // useEffect(() => {
  //   loadTutorsProfiles();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tutorPage]);

  useEffect(() => {
    (async () => {
      setLoadingBlogs(true);
      await dispatch(getMoreBlogsAction({ pageNumber: 0 }));
      setLoadingBlogs(false);
    })();
    setShowSearchModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if user is present load users registered courses
  // useEffect(()=>{
  //   if(userData){
  //     dispatch(loadUserRegisteredCoursesAction())
  //   }
  // },[userData,dispatch])

  return (
    <Grid
      container
      gap="5em"
      sx={{
        overflowX: "auto",
        overflowY: "auto",
        paddingBottom: "2em",
        justifyContent: "center",
      }}
    >
      {/* banner */}
      <Grid
        item
        container
        xs={12}
        minHeight={{ xs: 0, sm: 0, md: 0, lg: 700 }}
        rowGap={3}
        cy-data="qbook-banner-container"
        maxWidth="md"
        justifyContent="center"
        sx={{ marginTop: { xs: "4em", sm: "4em", md: "8em", lg: "0" } }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          columnGap={{ xs: "1em", sm: "1em", md: "1em", lg: "0" }}
        >
          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "1em",
                flexWrap: "wrap",
              }}
            >
              <Typography
                variant="h2"
                fontWeight={900}
                sx={{
                  color: "var(--landing-text-clr1)",
                  fontFamily: "var(--global-font-family-secondary)",
                }}
              >
                {DOMAIN_CONFIG_OBJ.landingPage.bannerHeading}
              </Typography>
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{
                  color: "var(--landing-text-clr1)",
                  fontFamily: "var(--global-font-family-primary)",
                }}
              >
                {DOMAIN_CONFIG_OBJ.landingPage.bannerText}
              </Typography>

              <CustomSearchField
                value={searchQuery}
                handler={(value: string) => handleSearchCourseFocus(value)}
                showButton={false}
                placeHolder="Search Qbook"
              />
            </Grid>
          </Slide>
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "none", lg: "block" },
                  minHeight: "80px",
                  minWidth: "80px",
                  position: "absolute",
                  borderRadius: "50%",
                  backgroundImage:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? `url(${atomImage})`
                      : "var(--landing-circle-gradient3)",
                  backgroundSize:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "contain"
                      : "",
                  top: "15%",
                  left: "20%",
                }}
              />
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "none", lg: "block" },
                  minHeight:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "150px"
                      : "80px",
                  minWidth:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "150px"
                      : "80px",
                  position: "absolute",
                  borderRadius: "50%",
                  backgroundImage:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? `url(${atomImage})`
                      : "var(--landing-circle-gradient1)",
                  backgroundSize:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "contain"
                      : "",
                  backgroundRepeat:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "no-repeat"
                      : "",
                  top: "30%",
                  left: "80%",
                }}
              />
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "none", lg: "block" },
                  minHeight:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "150px"
                      : "80px",
                  minWidth:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "150px"
                      : "80px",
                  position: "absolute",
                  borderRadius: "50%",
                  backgroundImage:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? `url(${atomImage})`
                      : "var(--landing-circle-gradient2)",
                  backgroundSize:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "contain"
                      : "",
                  backgroundRepeat:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? "no-repeat"
                      : "",
                  bottom: "16%",
                  left: "15%",
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  minWidth: "300px",
                  minHeight: "250px",
                  borderRadius: "77% 23% 70% 30% / 83% 65% 35% 17% ",
                  backgroundImage:
                    DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera
                      ? ""
                      : "var(--landing-Amorphous-gradient1)",
                }}
              >
                <img
                  src={DOMAIN_CONFIG_OBJ.landingPage.bannerImage.image}
                  alt={`${DOMAIN_CONFIG_OBJ.domainName} banner`}
                  style={{
                    position: "absolute",
                    width: DOMAIN_CONFIG_OBJ.landingPage.bannerImage.width,
                    height: DOMAIN_CONFIG_OBJ.landingPage.bannerImage.height,
                    left: DOMAIN_CONFIG_OBJ.landingPage.bannerImage
                      .positionLeft,
                    top: DOMAIN_CONFIG_OBJ.landingPage.bannerImage.positionTop,
                  }}
                />
              </Box>
            </Grid>
          </Slide>
        </Grid>
      </Grid>
      {/* why choose us */}
      <Grid
        item
        container
        xs={12}
        textAlign="center"
        justifyContent="center"
        gap={2}
        cy-data="qbook-choose-container"
        maxWidth="md"
      >
        <Grid
          item
          xs={12}
          gap={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <LandingLabel
            title={DOMAIN_CONFIG_OBJ.landingPage.whyChoose.title}
            text={DOMAIN_CONFIG_OBJ.landingPage.whyChoose.text}
          />
        </Grid>
        {DOMAIN_CONFIG_OBJ.landingPage.whyChoose.whyChooseCards.map(
          (data, _index) => {
            return (
              <WhyChooseCard
                bgColor={data.bgColor}
                icon={WhyChooseData[data.id as keyof typeof WhyChooseData]}
                label={data.label}
                value={data.value}
                key={`${data.label}_${_index}`}
              />
            );
          }
        )}
      </Grid>
      {/* List Courses */}
      <Grid
        item
        xs={12}
        container
        maxWidth="md"
        gap="2rem"
        justifyContent="center"
        alignItems="center"
        cy-data="qbook-course-container"
      >
        <Grid
          item
          xs={12}
          gap={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <LandingLabel
            title={DOMAIN_CONFIG_OBJ.landingPage.listCourse.title}
            text={DOMAIN_CONFIG_OBJ.landingPage.listCourse.text}
          />
          <CustomSearchField
            width="800px"
            value={searchQuery}
            handler={setSearchQuery}
            // enableAutoFocus={true}
            showButton={false}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          justifyContent="center"
        >
          <Grid item container gap="1em" xs={12} sm={12} md={12} lg={12}>
            {loadingCourses ? (
              Array(publicCourses?.length || 3)
                .fill("")
                .map((item, index) => (
                  <LoadingCourseCard key={`course_card_${index}`} />
                ))
            ) : !loadingCourses && failedToLoadCourses ? (
              <Box width="100%" textAlign="center">
                <Typography variant="h6" color="indianred">
                  Sorry failed to load the courses
                </Typography>
              </Box>
            ) : publicCourses.length === 0 && searchQuery.length > 0 ? (
              <Box width="100%" textAlign="center">
                <Typography variant="h6" color="var(--text-primary-clr2)">
                  Sorry, we can't find, what you are looking for
                </Typography>
              </Box>
            ) : publicCourses.length === 0 && searchQuery.length === 0 ? (
              <Box width="100%" textAlign="center">
                <Typography variant="h6" color="var(--text-primary-clr2)">
                  No Courses found
                </Typography>
              </Box>
            ) : (
              publicCourses?.filter((course: any) => course.courseName)?.map((course: PublicCourseType, index: number) => (
                <CourseCard
                  key={`course_card${index}`}
                  content={course.content}
                  courseDescription={course.courseDescription}
                  courseName={course.courseName}
                  totalStudents={course.totalStudents}
                  courseId={course?._id}
                  visibility={course?.visibility}
                  courseAuthor={course.courseAuthorUserName}
                  courseOwnerId={course.user}
                />
              ))
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="qbookStyledBtn"
            sx={{
              textTransform: "none",
              borderRadius: "var(--landing-page-btn-border-radius)",
            }}
            onClick={() => {
              handleLoadMoreCourses();
            }}
          >
            {loadingCourses
              ? "Loading..."
              : remainingCourses > 0
              ? "Load More"
              : "All Courses Loaded"}
          </Button>
        </Grid>
      </Grid>
      {/* List blogs */}
      <Grid
        item
        container
        xs={12}
        maxWidth="md"
        gap="2rem"
        justifyContent="center"
        alignItems="center"
        cy-data="qbook-blogs-container"
      >
        <Grid
          item
          xs={12}
          gap={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <LandingLabel
            title={DOMAIN_CONFIG_OBJ.landingPage.listBlogs.title}
            text={DOMAIN_CONFIG_OBJ.landingPage.listBlogs.text}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          justifyContent="center"
        >
          <Grid
            item
            container
            gap="1em"
            xs={12}
            justifyContent={{ md: "left", lg: "left" }}
          >
            {loadingBlogs ? (
              Array(4)
                .fill("")
                .map((item, index) => (
                  <LoadingBlogCard key={`blog_card_${index}`} />
                ))
            ) : !blogArray.length ? (
              <Box width="100%" textAlign="center">
                <Typography variant="h6" color="var(--text-primary-clr2)">
                  Sorry, no blogs are available
                </Typography>
              </Box>
            ) : (
              blogArray?.slice(0, 4)?.map((blog: any, index: number) => (
                <Grid item xs={12} sm={12} md={12} lg={5.8} key={blog._id}>
                  <BlogCard
                    blog={blog}
                    handleAddBookmark={handleAddBookmark}
                    isBookmarked={
                      userData
                        ? blog.cardInfo.bookmarkedBy.includes(userData._id)
                        : false
                    }
                    minimumHeight="180px"
                    isLandingPage={true}
                  />
                  <Divider light />
                </Grid>
              ))
            )}
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={3.5}></Grid> */}
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="qbookStyledBtn"
            sx={{
              textTransform: "none",
              borderRadius: "var(--landing-page-btn-border-radius)",
            }}
            onClick={() => handleViewMoreBlogs()}
          >
            View More
          </Button>
        </Grid>
      </Grid>
      {/* List Tutors */}
      <Grid item xs={12} container gap="2rem" cy-data="qbook-tutor-container">
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {/* <Grid
            item
            container
            gap={1}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            justifyContent="center"
            flexWrap="wrap"
          >
            {loadingTutorProfiles ? (
              Array(3)
                .fill("")
                .map((item, index) => (
                  <LoadingProfileCard key={`tutor_profile_card_${index}`} />
                ))
            ) : failedToLoadTutorProfiles && !loadingTutorProfiles ? (
              <Box>
                <Typography variant="h6" color="indianred">
                  Sorry failed to load tutors
                </Typography>
              </Box>
            ) : (
              tutorProfilesArray?.map(
                (tutor: TutorProfileType, index: number) => (
                  <TutorProfileCard
                    key={`tutor_profile_card_${index}`}
                    tutorProfile={tutor}
                  />
                )
              )
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={3.5}></Grid> */}
        </Grid>
        {/* <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="qbookStyledBtn"
            sx={{
              textTransform: "none",
              borderRadius: "var(--landing-page-btn-border-radius)",
            }}
            onClick={() => handleLoadMoreTutorProfiles()}
          >
            {loadingTutorProfiles ? "Loading..." : "Load More"}
          </Button>
        </Grid> */}
      </Grid>
      {showSearchModal && (
        <CommonSearch open={showSearchModal} closeHandler={handleSearchModal} />
      )}
    </Grid>
  );
};

export default Landing;
