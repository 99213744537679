import { Grid, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

export const WhyChooseData = {
  crs: <MenuBookIcon />,
  std: <GroupsIcon />,
  cls: <SchoolIcon />,
  org: <WorkHistoryIcon />,
};

const WhyChooseCard = ({
  label,
  value,
  icon,
  bgColor,
  selectionHandler,
}: {
  label: string;
  value: string;
  icon: any;
  bgColor: string;
  selectionHandler?: () => {};
}) => {
  return (
    <Grid
      item
      container
      xs={11}
      sm={11}
      md={11}
      lg={5}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        boxShadow: "0px 0px 17px 0px rgba(0,0,0,0.11)",
        borderRadius: "5px",
        padding: "1em",
        gap: "1em",
        backgroundColor: "var(--landing-choose-card-bg)",
      }}
    >
      <Grid
        item
        xs={1}
        sx={{
          background: bgColor,
          color: "var(--landing-choose-card-text1)",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50px",
          minWidth: "50px",
          textAlign: "left",
        }}
      >
        {icon}
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h6"
          color="var(--landing-choose-card-text2)"
          textAlign="left"
          fontWeight="700"
          fontSize="26px"
        >
          {label}
        </Typography>
        <Typography
          variant="body2"
          color="var(--landing-choose-card-text3)"
          sx={{
            textAlign: "left",
            fontSize: "20px",
            fontWeight: 600,
            maxHeight: "80px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
          }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WhyChooseCard;
