// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-editor-button {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: transparent;
    fill: #88888b;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    color: currentColor;
    padding: 0;
    opacity: 0.5;
  }
  .image-editor-button--active {
    background: rgba(255, 255, 255, 0.03);
    fill: currentColor;
    opacity: 1 !important;
  }
  .image-editor-button:hover, .image-editor-button:focus {
    background: rgba(255, 255, 255, 0.03);
  }
  @media (max-width: 540px) {
    .image-editor-button {
      width: 32px;
      height: 32px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/pages/editProfile/components/profile-details/components/imageEditor/components/button/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,UAAU;IACV,YAAY;EACd;EACA;IACE,qCAAqC;IACrC,kBAAkB;IAClB,qBAAqB;EACvB;EACA;IACE,qCAAqC;EACvC;EACA;IACE;MACE,WAAW;MACX,YAAY;IACd;EACF","sourcesContent":[".image-editor-button {\n    width: 46px;\n    height: 46px;\n    border-radius: 50%;\n    background: transparent;\n    fill: #88888b;\n    transition: 0.5s;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: none;\n    outline: none;\n    cursor: pointer;\n    color: currentColor;\n    padding: 0;\n    opacity: 0.5;\n  }\n  .image-editor-button--active {\n    background: rgba(255, 255, 255, 0.03);\n    fill: currentColor;\n    opacity: 1 !important;\n  }\n  .image-editor-button:hover, .image-editor-button:focus {\n    background: rgba(255, 255, 255, 0.03);\n  }\n  @media (max-width: 540px) {\n    .image-editor-button {\n      width: 32px;\n      height: 32px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
